// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {      
        primary: '#e56a1b',
        secondary: '#323742',
        accent: '#da6215',
        gradient: '#bd4e06',

        "layout-appbar__appbar": "#FFFFFF",
      },
      dark: {
        primary: '#e56a1b',
        secondary: '#262931',
        accent: '#da6215',
      },
    },
  },
})
